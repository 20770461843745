import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { JwtHelper } from 'angular2-jwt';
import { Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { AlertService } from './service/alert.service';
import { AuthService } from './service/auth.service';
import { SiteControllerService } from './service/sitecontroller.service';
import { SiteController } from './types/data-types';
import { CustomTranslateService } from './service/customTranslate.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
	title = 'CARVI-app';
	isAuthenticated = false;
	currentUrl = '';
	sub: Subscription;
	current_token_time = 0;
	siteController: SiteController[] = [];
	lang = '';
	transObj = {};
	constructor(
		private authService: AuthService,
		private siteControllerService: SiteControllerService,
		private customTranslateService: CustomTranslateService,
		private router: Router
	) {
		this.router.events
			.pipe(filter((event) => event instanceof NavigationEnd))
			.subscribe((route: NavigationEnd) => {
				if (!this.currentUrl && route.url.indexOf('/auth/') === -1) {
					this.currentUrl = route.url;
					this.authService.setSesstionStart();
				}

				if (!!this.currentUrl) {
					if (this.currentUrl !== route.url) {
						this.authService.refreshSession();
						this.authService.setSesstionStart();
					}
				}
			});
	}

	ngOnDestroy(): void {
		// console.log('app component destroy');
		this.authService.logout();
	}

	ngOnInit() {
		this.customTranslateService
			.getLang()
			.pipe(
				switchMap((value) => {
					this.lang = value ? value : 'ko';
					return this.customTranslateService.sendJson(this.lang);
				})
			)
			.subscribe((value) => {
				this.transObj = value;
			});
		// console.log(this.authService.getToken());
		if (!!this.authService.getToken()) {
			if (JwtHelper.prototype.isTokenExpired(this.authService.getToken())) {
				this.currentUrl = '';
				this.authService.setSesstionStop();
				this.authService.logout();
				this.router.navigate(['/auth/sign-in']);
				localStorage.clear();
			}
			// console.log(JwtHelper.prototype.isTokenExpired(this.authService.getToken()));
		} else {
			console.log('notToken');
		}
		// this.siteControllerService.getSiteController().then((data) => {
		// 	const body: SiteController[] = JSON.parse(data.Body.toString());
		// 	this.siteController = body;
		// 	this.siteControllerService.setControllTrigger(this.siteController);
		// });
		this.authService.initAuth().subscribe((value) => {
			// console.log('appcomp initauth')
			if (
				!!value &&
				value === 'notLogin' &&
				!!this.authService.getToken() &&
				!JwtHelper.prototype.isTokenExpired(this.authService.getToken())
			) {
				console.log('appcomp session timeout');
				this.currentUrl = '';
				this.authService.setSesstionStop();
				// this.alertService.alertDialog(this.transObj['ALERTS']['Session is expired. Please try to log in again']);
				this.authService.logout();
				this.router.navigate(['/auth/sign-in']);
				localStorage.clear();
			} else if (!!this.authService.getToken()) {
				if (JwtHelper.prototype.isTokenExpired(this.authService.getToken())) {
					this.authService.setSesstionStop();
					// this.alertService.alertDialog(this.transObj['ALERTS']['Session is expired. Please try to log in again']);
					this.authService.logout();
					this.router.navigate(['/auth/sign-in']);
					localStorage.clear();
				}
				// console.log(JwtHelper.prototype.isTokenExpired(this.authService.getToken()));
			} else {
				console.log('notToken');
			}
		});

		// this.authService.authStatusChanged.subscribe(
		//   (authenticated) => {
		//     // console.log(authenticated, 'authenticated - app');
		//     this.isAuthenticated = authenticated;
		//     console.log('authendidtsadsfaf',authenticated);
		//     if (authenticated) {
		//     //  this.router.navigate(['/dashboard']);
		//     //  if(!window.location.href.split('#')[1]
		//     //  || window.location.href.split('#')[1] == '/'
		//     //  || window.location.href.split('#')[1] == '/auth/sign-in') {
		//     //   this.router.navigate(['/dashboard']);
		//     //  }

		//     } else {
		//       // this.router.navigate(['/']);
		//     }
		//   }
		// );
	}

	onLogout() {
		this.authService.logout();
	}
}
